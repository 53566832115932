export default defineNuxtRouteMiddleware((route) => {
  const { uid, token } = route.params

  if (uid && token) {
    return navigateTo({
      name: 'auth-password-reset-confirm',
      query: {
        uid,
        token,
        status: 'verified'
      }
    })
  }
})
